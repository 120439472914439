const componentNameMapping = {
  "account-onboarding": "stripe-connect-account-onboarding"
};
const EXISTING_SCRIPT_MESSAGE = "loadConnect was called but an existing Connect.js script already exists in the document; existing script parameters will be used";
const V0_URL = "https://connect-js.stripe.com/v1.0/connect.js";
const findScript = () => {
  return document.querySelectorAll(`script[src="${V0_URL}"]`)[0];
};
const injectScript = () => {
  const script = document.createElement("script");
  script.src = "https://connect-js.stripe.com/v0.1/connect.js";
  const head = document.head;
  if (!head) {
    throw new Error("Expected document.head not to be null. Connect.js requires a <head> element.");
  }
  document.head.appendChild(script);
  return script;
};
let stripePromise = null;
const loadScript = () => {
  // Ensure that we only attempt to load Connect.js at most once
  if (stripePromise !== null) {
    return stripePromise;
  }
  stripePromise = new Promise((resolve, reject) => {
    if (typeof window === "undefined") {
      reject("ConnectJS won't load when rendering code in the server - it can only be loaded on a browser. This error is expected when loading ConnectJS in SSR environments, like NextJS. It will have no impact in the UI, however if you wish to avoid it, you can switch to the `pure` version of the connect.js loader: https://github.com/stripe/connect-js#importing-loadconnect-without-side-effects.");
      return;
    }
    if (window.StripeConnect) {
      console.warn(EXISTING_SCRIPT_MESSAGE);
    }
    if (window.StripeConnect) {
      const wrapper = createWrapper(window.StripeConnect);
      resolve(wrapper);
      return;
    }
    try {
      let script = findScript();
      if (script) {
        console.warn(EXISTING_SCRIPT_MESSAGE);
      } else if (!script) {
        script = injectScript();
      }
      script.addEventListener("load", () => {
        if (window.StripeConnect) {
          const wrapper = createWrapper(window.StripeConnect);
          resolve(wrapper);
        } else {
          reject(new Error("Connect.js did not load the necessary objects"));
        }
      });
      script.addEventListener("error", () => {
        reject(new Error("Failed to load Connect.js"));
      });
    } catch (error) {
      reject(error);
    }
  });
  return stripePromise;
};
const initStripeConnect = stripeConnectPromise => {
  if (stripeConnectPromise === null) {
    return null;
  }
  return stripeConnectPromise;
};
const createWrapper = stripeConnect => {
  const wrapper = {
    initialize: params => {
      var _a;
      const metaOptions = (_a = params.metaOptions) !== null && _a !== void 0 ? _a : {};
      const stripeConnectInstance = stripeConnect.init(Object.assign(Object.assign({}, params), {
        metaOptions: Object.assign(Object.assign({}, metaOptions), {
          sdk: true,
          sdkOptions: {
            // This will be replaced by the npm package version when bundling
            sdkVersion: "2.0.4"
          }
        })
      }));
      // We wrap create so we can map its different strings to supported components
      const oldCreate = stripeConnectInstance.create.bind(stripeConnectInstance);
      stripeConnectInstance.create = tagName => {
        let htmlName = componentNameMapping[tagName];
        if (!htmlName) {
          htmlName = tagName;
        }
        return oldCreate(htmlName);
      };
      return stripeConnectInstance;
    }
  };
  return wrapper;
};

// Execute our own script injection after a tick to give users time to do their
// own script injection.
const stripePromise$1 = Promise.resolve().then(() => loadScript());
let loadCalled = false;
stripePromise$1.catch(err => {
  if (!loadCalled) {
    console.warn(err);
  }
});
const loadConnect = () => {
  loadCalled = true;
  return stripePromise$1.then(maybeConnect => initStripeConnect(maybeConnect));
};

export { loadConnect };
